// This file is generated dynamically per-request and cannot be used directly.
// To use WURFL.js, please see the documentation at https://web.wurfl.io/#wurfl-js
//
// Copyright 2023 - ScientiaMobile, Inc., Reston, VA
// WURFL Device Detection
// Terms of service:
// https://www.scientiamobile.com/terms-of-service-wurfl-js-lite/
//
// For improved iPhone/iPad detection, upgrade to WURFL.js Business Edition:
// https://www.scientiamobile.com/products/wurfl-js/

var WURFL={complete_device_name:"Google Chrome",form_factor:"Desktop",is_mobile:!1},newEvent;"CustomEvent"in window?document.dispatchEvent(new Event("WurflJSDetectionComplete",{bubbles:!0})):(newEvent=document.createEvent("CustomEvent"),newEvent.initEvent("WurflJSDetectionComplete",!0,!0),document.dispatchEvent(newEvent))